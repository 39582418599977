import { makeAutoObservable } from "mobx";

export default class GetChangeOilReportRequest
{
    carIdNumber: string;
    carId: number;
    companyBranchName: string;
    companyBranchId: number;
    carNeedChangeOil: number;
    companyId: number;
   
    companyName: string;
    pageIndex: number;
    pageSize: number;
    exportToFile: boolean;
    
   

    constructor(
    ) {
        makeAutoObservable(this);
    }
}
