export default {
    appStore: 'appStore',
    pageStore: 'pageStore',
    authStore: 'authStore',
    customerStore: 'customerStore',
    supplierStore: 'supplierStore',
    adminStore: 'adminStore',
    companiesStore: 'companiesStore',
    petrolCompaniesStore: 'petrolCompaniesStore',
    bundlesStore: 'bundlesStore',
    branchStore: 'branchStore',
    petroStationStore: 'petroStationStore',
    stationUserStore: 'stationUserStore',
    carUserStore: 'carUserStore',
    carStore: 'carStore',
    rechargeBalanceStore: 'rechargeBalanceStore',
    subscriptionStore: 'subscriptionStore',
    invoiceSummaryStore: 'invoiceSummaryStore',
    changeOilReportStore:'changeOilReportStore',
    invoiceSummaryByCarStore: 'invoiceSummaryByCarStore',
    invoiceSummaryByBranchStore: 'invoiceSummaryByBranchStore',
    invoiceReviewStore: 'invoiceReviewStore',
    invoiceDetailStore: 'invoiceDetailStore',
    carBalanceStore: 'carBalanceStore',
    stationReportStore: 'stationReportStore',
    stationSaleStore: 'stationSaleStore',
    stationStatementStore: 'stationStatementStore',
    carTransactionStore: 'carTransactionStore',
    accountBalanceStore: 'accountBalanceStore',
    petrolStationListStore: 'petrolStationListStore',
    transferBalanceStore: 'transferBalanceStore',
    petropayAccountStore: 'petropayAccountStore',
    promotionCouponStore: 'promotionCouponStore',
    carConsumptionRateStore: 'carConsumptionRateStore',
    carKmConsumptionStore: 'carKmConsumptionStore',
    carOdometerMaxStore: 'carOdometerMaxStore',
    carListReportStore: 'carListReportStore',
    carOdometerMinStore: 'carOdometerMinStore',
    odometerBetweenDateStore: 'odometerBetweenDateStore',
    appSettingStore: 'appSettingStore',
    odometerRecordStore: 'odometerRecordStore',
    menuStore: 'menuStore',
    employeeStore: 'employeeStore',
    odometerHistoryStore: 'odometerHistoryStore',
    newCustomerStore: 'newCustomerStore',
    transferBonusStore: 'transferBonusStore',
    customerStatementStore: 'customerStatementStore',
    customerStatementSummaryStore: 'customerStatementSummaryStore',
    companyBranchStatementStore: 'companyBranchStatementStore',
    oilPricesStore:'oilPricesStore',
    subscriptionNFCStore: 'subscriptionNFCStore',
    
}
