export default
[
    /*{title: "InvoiceSummaries.Label.key", dataIndex: "key", key: "key", responsive: ['md']},*/
   
    /*{title: "InvoiceSummaries.Label.companyBranchId", dataIndex: "companyBranchId", key: "companyBranchId", responsive: ['md']},*/
    {title: "InvoiceSummaries.Label.companyBranchName", dataIndex: "companyBranchName", key: "companyBranchName", responsive: ['md']},

      {title: "InvoiceSummaries.Label.key", dataIndex: "carId", key: "carId", responsive: ['md']},
    {title: "InvoiceSummaries.Label.carIdNumber", dataIndex: "carIdNumber", key: "carIdNumber", responsive: ['md']},
    {title: "InvoiceSummaries.Label.carDriverName", dataIndex: "carDriverName", key: "carDriverName", responsive: ['md']},

    {title: "ChangeOilReport.Label.carChangeOilKmCap", dataIndex: "carChangeOilKmCap", key: "carChangeOilKmCap", responsive: ['md']},
    {title: "ChangeOilReport.Label.lastChangeOilOdometer", dataIndex: "lastChangeOilOdometer", key: "lastChangeOilOdometer", responsive: ['md']},
    {title: "ChangeOilReport.Label.lastOdmeterRecord", dataIndex: "lastOdmeterRecord", key: "lastOdmeterRecord", responsive: ['md']},
    {title: "ChangeOilReport.Label.carKmConsumptionFromChangeOil", dataIndex: "carKmConsumptionFromChangeOil", key: "carKmConsumptionFromChangeOil", responsive: ['md']},
    //{title: "ChangeOilReport.Label.carKmConsumptionFromChangeOil", dataIndex: "changeOilFlag", key: "changeOilFlag", responsive: ['md']}
]

