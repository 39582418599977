import React, {useState,useEffect, useContext} from 'react';
import {inject, observer} from "mobx-react";
import Stores from "app/constants/Stores";
import CustomerStore from "../../stores/CustomerStore";
import i18next from "i18next";
import UserContext from "../../../identity/contexts/UserContext";
import GetCustomerRequest from "../../handlers/get/GetCustomerRequest";
import {Alert, Avatar, Badge, Button, Card, Col, Descriptions, Divider, Grid, List, Row, Spin, Statistic, Table, Tag, notification} from "antd";

import CompanySubscriptionItemColumns from "./CompanySubscriptionItemColumns";
import CompanyBranchItemColumns from "./CompanyBranchItemColumns";
import {ArrowDownOutlined, ArrowUpOutlined, CheckOutlined, ClockCircleOutlined} from "@ant-design/icons";
import Meta from 'antd/lib/card/Meta';
import ReactDOM from 'react-dom';
import { Line, Column,Bar,G2,TinyLine} from '@ant-design/plots';
import { Pie } from '@ant-design/plots';

import {CarOutlined } from '@ant-design/icons';
import { DualAxes } from '@ant-design/charts';
import { now } from 'moment';
import CompanyInvoiceAlartItemColumns from './CompanyInvoiceAlartItemColumns';
import { color } from 'highcharts';
import { ExclamationCircleOutlined  } from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';






const openNotification = (e,messText,desText) => {
  notification.open({
    message:messText, // i18next.t("CustomerDashboard.Section.HighConsumptionNotification"),
    description:e+ desText,//i18next.t("CustomerDashboard.Section.HighConsumptionNotificationText"),
    className: 'notificationclass',
    style: {
      width: 500,
    
    },
    duration:50,
    icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,


  });
};

const openNotificationChangeOil = (e,messText,desText) => {
  notification.open({
    message:messText, // i18next.t("CustomerDashboard.Section.HighConsumptionNotification"),
    description:e+ desText,//i18next.t("CustomerDashboard.Section.HighConsumptionNotificationText"),
    className: 'notificationclass',
    style: {
      width: 500,
    
    },
    duration:75,
    icon:  <img src="images/engine-oil.png"  width={30} />,


  });
};


interface DashboardProps {
    customerStore?: CustomerStore
}

const CustomerDashboard: React.FC<DashboardProps> = inject(Stores.customerStore)(observer(({customerStore}) =>
{
    const [dataFetched, setDataFetched] = React.useState(false);
    CompanySubscriptionItemColumns.forEach(w => {
        w.title = i18next.t(w.title);
        if(w.key === "alarm"){
            w["render"] = (w) => {
                return  w ? <Badge count={<ClockCircleOutlined style={{   color: '#f5222d'  } } />} /> : "";
            }
        }
    });

    const subscriptionColumns: any[] = [...CompanySubscriptionItemColumns];

    CompanyBranchItemColumns.forEach(w => {
        w.title = i18next.t(w.title);
    });

    const branchColumns: any[] = [...CompanyBranchItemColumns];


    CompanyInvoiceAlartItemColumns.forEach(w=>{
      w.title=i18next.t(w.title);
    });
    
    const companyInvoiceAlartItemColumns: any[]=[...CompanyInvoiceAlartItemColumns];

    useEffect(() => {
        onLoad();

        return onUnload;
    }, []);

    async function onLoad() {
        customerStore.onCustomerGetPageLoad();
        customerStore.getCustomerViewModel.getCustomerRequest = new GetCustomerRequest();
        if(UserContext.info.role === 1)
            customerStore.getCustomerViewModel.getCustomerRequest.companyId = UserContext.info.id;
        if(UserContext.info.role === 5)
            customerStore.getCustomerViewModel.getCustomerRequest.companyBranchId = UserContext.info.id;


        await customerStore.getCustomerViewModel.getDashboardData(customerStore.getCustomerViewModel.getCustomerRequest);

       let highCarRate=  customerStore.getCustomerViewModel.totalCompanyCarHasConsumptionAlart;
       let subscripNearExpiry =  customerStore.getCustomerViewModel.totalSubscriptionNearExpiry;
       let totalCountNeedChangeOil= customerStore.getCustomerViewModel.totalCountOfCarNeedChangeOil;

     //  message:messText, // i18next.t("CustomerDashboard.Section.HighConsumptionNotification"),
       //description:e+ desText,//i18next.t("CustomerDashboard.Section.HighConsumptionNotificationText"),

       
       if (highCarRate !== 0){
       openNotification(highCarRate,i18next.t("CustomerDashboard.Section.HighConsumptionNotification"),i18next.t("CustomerDashboard.Section.HighConsumptionNotificationText"));
       }
       if (subscripNearExpiry !== 0){
        openNotification(subscripNearExpiry,i18next.t("CustomerDashboard.Section.NearExpirySubNotification"),i18next.t("CustomerDashboard.Section.NearExpirySubNotificationText"));
       }
      
       if ( totalCountNeedChangeOil!== 0){
        openNotificationChangeOil(totalCountNeedChangeOil,i18next.t("ChangeOilReport.Page.Title"),i18next.t("CustomerDashboard.Section.CarNeedChangeOil"));
       }





        setDataFetched(true);

        
    }

    let viewModel = customerStore.getCustomerViewModel;

    if (!viewModel) return;

    function onUnload() {
        customerStore.onCustomerGetPageUnload();
    }

    //////

    
    

    /////////
        
    let data2= viewModel?.companyMonthlyInvoiceItems?.map((d) => {
  return {
    month: d.monthOfInvoices,
    value: [(Math.round( d.consumptionLiter/d.carCount))],
    year: d.yearOfInvoices,
  }
});
let data3= viewModel?.companyMonthlyInvoiceItems?.map((d) => {
  return {
    month: d.monthOfInvoices,
    value: [d.carCount,(Math.round( d.totalCost/d.carCount))],
    year: d.yearOfInvoices,
  }
});

let companyConsumpationByServicedata= viewModel?.monthlyConsumptionByServiceItems?.map((d) => {
  return {
   
    type:  d.serviceArDescription,

    value: d.totalCost,
  }
});
    
let dataBranchshare = viewModel?.companyBranchMonthlyInvoicesItems?.map((d) => {
  return {
    type:  d.companybranchname,

    value:d.totalCost,
  
  }
});

let dataBranchcarCount = viewModel?.companyBranchMonthlyInvoicesItems?.map((d) => {
  return {
    type:  d.companybranchname,

    value:d.carCount,
  
  }
});



    let data = viewModel?.companyMonthlyInvoiceItems?.map((d) => {
      return {
        month: d.monthOfInvoices,
        value:(Math.round( d.totalCost)),
        year: d.yearOfInvoices,
      }

      
    });

    
   
    




 

  const activeCardata = [
    {
      
      
     
      type: i18next.t("CustomerDashboard.Section.InActiveCars"),
            value:viewModel?.totalCompanyInActiveCar,
    
      },{
        type: i18next.t("CustomerDashboard.Section.ActiveCars"),
        value:viewModel?.totalCompanyActiveCar,
    
      }
  ]
   
     const config = {
     
    xField: 'month',
    yField: 'value',
    seriesField: 'year' ,  
    colorField:'value',
    height: 300,
    autoFit: true,
    legend: {
      
      offsetX: 200,
     
         
    },
       
  
    label: {  
        style: {
        fill: '#BA4A00',
       
       
        lineWidth: 1,
         
        shadowOffsetX: 5,
        shadowOffsetY: 5,
        cursor: 'pointer'
      },
    
        },

        yAxis: {
           
          tickCount: 0,
          
        },
   
       
    point: {
      size: 5,
      shape: 'circle',
      style: {
        fill: '#FFF80F ',
        stroke: 'blue',
        lineWidth: 1,
        
      },
    },
   // interactions: [{ type: 'element-active' }],
     
    state: {
      active: {
        animate: { duration: 100000, easing: 'easeLinear' },
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: '#fffbed',

          lineWidth: 2,
          
        },
       
      },
      
    },
   
   
   
  };
    ////
    
    

      const configshare = {
        appendPadding: 10,
        dataBranchshare,
        angleField: 'value',
        colorField: 'type',
        height:300,
      
         autoFit: true,
        radius: 0.70,
        label: {
          type: 'spider',
          labelHeight: 50,
          content: '{name}\n{percentage}',
        },
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
        ],
      };
     
    ////
   
   
//////



 const chart2 = {
   data2,
    xField: 'month',
    yField: 'value',
    seriesField: 'year' ,  
    
    height: 300,
    autoFit: true,
    legend: {
      
      offsetX: 200,
      
         
    },
  
    label: {
      style: {
      fill: '#BA4A00',
      lineWidth: 1,
        shadowOffsetX: 5,
      shadowOffsetY: 5,
      cursor: 'pointer',
     position: 'middle'

    },
  },

        yAxis: {
           
          tickCount: 0,
          
        },
   
       
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
     
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: '#fffbed',
        },
       
      },
      
    },
   
   
   
  };
////



const configActiveCar = {
 
   
  appendPadding: 10,
  activeCardata,
  height: 280,
 // width:300,
   autoFit: true,
   
  angleField: 'value',
  colorField: 'type',
  radius: 1,
  innerRadius: 0.5,
  meta: {
    value: {
      formatter: (v) => `${v} `,
    },
  },
  label: {
    type: 'inner',
    offset: '-40%',
    
    style: {
      textAlign: 'center',
      fontSize: 24,
      rotate: true,
     

    },
    autoRotate: false,
    content: '{value}',
    
  },
  Legend:{
    layout: 'horizontal',
    position: 'right',
    style: {
      textAlign: 'right',
      fontSize: 24,
      rotate: true,
        //  layout: 'v',
      position: 'left'

    },

  }
 
 
};

const configPieConsumptionByService = {
 
    
  appendPadding: 10,
  activeCardata,
  angleField: 'value',
  colorField: 'type',
  autoFit: true,
  //innerRadius: 0.1,
  height: 300,
  
  label: {
    text: 'value',
   // text: (d) => `${d.type}\n ${d.value}`,
    style: {
      fontWeight: 'bold',
      fontSize: 14,
    },

   
  },
   interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
        ],
  
 
 
};


////

const configFeulingCar = {
 
   
  appendPadding: 15,
  activeCardata,
  height: 300,
 // width:300,
   autoFit: true,
   
  angleField: 'value',
  colorField: 'type',
  radius: 1,
  innerRadius: 0.5,
  meta: {
    value: {
      formatter: (v) => `${v} `,
    },
  },
  label: {
    type: 'inner',
    offset: '-40%',
    
    style: {
      textAlign: 'center',
      fontSize: 24,
      rotate: true,
     

    },
    autoRotate: false,
    content: '{value}',
    
  },
  Legend:{
    layout: 'horizontal',
    position: 'right',
    style: {
      textAlign: 'right',
      fontSize: 24,
      rotate: true,
        //  layout: 'v',
      position: 'left'

    },

  }
 
 
};












    return (
      
        <div className='dashbord_page'>
        {dataFetched ?

     


///////////////////////////////////////////
            <div>
                 
                 
      <Row className='row-card'  justify="space-around" align="bottom">


        
      
      {UserContext.info.role === 1 ?
      //----------
      

      <div className='flex-container'>
        
      <div className='chart_bardashbord'    >
 

      <Card  className='customerDashbord_balance'   >
  
          <img src="images/balance.png" width={35}  />   
           {i18next.t("CustomerDashboard.TotalCustomerBalance")} 
           <h2 className='mat-card'> { viewModel?.totalCustomerBalance?.toLocaleString()}  </h2>
           

      <Divider></Divider>
    
           {<img src="images/branchIcon.png"  width={35} /> }  
            {i18next.t("CustomerDashboard.TotalBranchBalance") }                   
            <h2 className='mat-card' > {viewModel?.totalBranchBalance?.toLocaleString()}</h2>
          
  
          <Divider></Divider>
          
          
          <img src="images/carBalance.png"  width={35} />  
           {i18next.t("CustomerDashboard.TotalCarBalance")}
           <h2 className='mat-card' >{viewModel?.totalCarBalance?.toLocaleString()}</h2>
           
           <Divider></Divider>
           { viewModel?.totalCountOfCarNeedChangeOil !==0?
        
                         
      <Badge count={viewModel?.totalCountOfCarNeedChangeOil?.toLocaleString()} offset={[0,- 10]}>
      <img src="images/engine-oil.png"  width={35}   /> 
     
      </Badge>
      

        //  <h1> <img src="images/engine-oil.png"  width={35} /> {viewModel?.totalCountOfCarNeedChangeOil?.toLocaleString()}</h1> 
        
          : "" }
                     
       </Card>


      
       </div>
 
 
 
       <div className='chart_bardashbord'>
 
     <Card className='customerDashbord_total_consumpation' >
       
       <Line data={ data} {...chart2} />
         <h4 className='chart_tital' > {i18next.t("CustomerDashboard.Section.MonthlyFleetChart")}  </h4>
        
             
       </Card>
       </div>
 
       
       <div className='chart_bardashbord'>
         
       <Card className='customerDashbord_serviceShare' >
            
       
             <Pie  data={companyConsumpationByServicedata} legend={ false} {...configPieConsumptionByService} />
    
             <h4 style={{textAlign:'center'}}>  {i18next.t("CustomerDashboard.Section.TotalFleetExpensesByServices")}</h4>
                
       </Card>
      
      
           
           
        </div>
     
        
       
    
     </div>
   
   

     
         : "" }



{UserContext.info.role === 5 ?
 
 
 
 <Col span={6}  className='flex-container>div '  style={{display:'flex' }} >
       
       

       <Card  title={i18next.t("CustomerDashboard.TotalBranchBalance") } className='dashbord-cart'  >
        <Meta className='mat-card'
          avatar={<img src="images/branchIcon.png"  width={45} />}
         
          description={viewModel?.totalBranchBalance?.toLocaleString()}
            />
      </Card>

 



  
      <Card  title={i18next.t("CustomerDashboard.TotalCarBalance")} className='dashbord-cart'  >
        <Meta className='mat-card'
          avatar={<img src="images/carBalance.png"  width={45} />}
          
          description={viewModel?.totalCarBalance?.toLocaleString()}
       
          
        />
      </Card>   
  
      </Col>
    
   
  
  
        
           : "" }
         
        

 
     
    </Row>



<Divider></Divider>

{/* {UserContext.info.role === 1 ? */}
      
    <div className='charts'>
       <div className='chart_pie' >
         <Card className='chart_bar'>
         <Line data={ data3} {...chart2}   />
         <h4 className='chart_tital' > {i18next.t("CustomerDashboard.Section.CarAverageConsumptionByAmount")} </h4>
          </Card>
         
      </div>


      <div className='chart_pie'>
        <Card className='chart_bar'>

                <Line data={ data2} {...chart2}   />
        <h4 className='chart_tital'> {i18next.t("CustomerDashboard.Section.CarAverageConsumption")} </h4>
        </Card>
        
          
          
       </div>
    
  
   
    </div>

    

    
{/* : "" } */}
      



      <Divider></Divider>

{UserContext.info.role === 1 ?
      
      <div className='flex-container'>
    <div className='chart_pie'>

     <Card className='chart_bar' >
   <h4 style={{textAlign:'center'}}>  {i18next.t("CustomerDashboard.Section.TotalCarsActiveInActive")}</h4>
      <Pie  data={activeCardata} legend={ false}  {...configActiveCar} />
      
      <div style={{display:'flex' ,textAlign:'center'}}>
          <h5 className='textColer1'>  <CarOutlined />  {i18next.t("CustomerDashboard.Section.InActiveCars")  }</h5>
          
         <h5 className='textColer2'>    <CarOutlined />      {i18next.t("CustomerDashboard.Section.ActiveCars")}</h5>
            
         </div>
        
      </Card>
      </div>



      <div className='chart_pie'>

    <Card className='chart_bar' >
      <h4 style={{textAlign:'center'}}>  {i18next.t("CustomerDashboard.Section.MTDFuelingCar")}</h4>
        <Pie  data={dataBranchcarCount} legend={ false}  {...configFeulingCar} />
       
        <div style={{display:'flex' ,textAlign:'center'}}>
          <h5 className='textColer1'>  </h5>
          
         <h5 className='textColer2'>  </h5>
            
         </div>
   
      </Card>
      </div>

      
      <div className='chart_pie'>
        
      <Card className='chart_bar' >
            <h4 style={{textAlign:'center'}}>  {i18next.t("CustomerDashboard.Section.BranchesConsumptionShare")}</h4>
      <Pie  data={dataBranchshare} legend={ false}  {...configshare} />
   
      <div style={{display:'flex' ,textAlign:'center'}}>
          <h5 className='textColer1'>  </h5>
          
         <h5 className='textColer2'>  </h5>
            
         </div>
        
      </Card>
     
      
          
          
       </div>
    
       
      
   
    </div>

    

    
: "" }
      
      <Divider></Divider>

      
      
      <div className='chart_bar'>
      
                 <br />
  
                         <Card   >
                      <Divider  className="dashbord-titl">{i18next.t("CustomerDashboard.Section.HighConsumptionCar")}</Divider> 
     
                        <br/>
                        <Table  style={{backgroundColor:'#fcedb6'} } className="dashbord-table" dataSource={viewModel?.companyInvoiceAlartItems} columns={companyInvoiceAlartItemColumns} loading={viewModel?.isProcessing}
                               bordered={true} pagination={false} scroll={{ y: 500 }} 
                               />
                        <br/>
                        </Card>
                  
                
                <br/>
                
                </div>
     
     



      <Divider></Divider>


{UserContext.info.role === 1 ?
      
      
      <div className='chart_bar'>
      
      
    
     
                 <br />
  
                         <Card  >
                      <Divider className="dashbord-titl">{i18next.t("CustomerDashboard.Section.BranchesBalance")}</Divider> 
     
                        <br/>
                        <Table className="dashbord-table" dataSource={viewModel?.companyBranchItems} columns={branchColumns} loading={viewModel?.isProcessing}
                               bordered={true} pagination={false} scroll={{ y: 500 }}
                               summary={() => (
                                <Table.Summary.Row className='totalTabl' >
                                    <Table.Summary.Cell index={0}>{i18next.t("General.Table.Total")}</Table.Summary.Cell>
                                    
                                    <Table.Summary.Cell index={5}>{viewModel?.totalBranchBalance?.toLocaleString()}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{viewModel?.totalCarBalance?.toLocaleString()}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{viewModel?.totalCompanyActiveCar.toLocaleString()}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{viewModel?.totalCompanyInActiveCar.toLocaleString()}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{viewModel?.totalBranchOdometerOffCarCount.toLocaleString()}</Table.Summary.Cell>
                                    <Table.Summary.Cell colSpan={0} index={0}></Table.Summary.Cell>
                                    
                                </Table.Summary.Row>
                            )}/>
                        <br/>
                        </Card>
                  
                
                <br/>
                
                </div>
     
     : "" }

{UserContext.info.role === 1 ?
      <div className='chart_bar'>
     
                 <br />
                                  
                    < Card >
                    <Divider  className="dashbord-titl" >{i18next.t("CustomerDashboard.Section.SubscriptionsNearExpiry")}</Divider>
                       <br/>
                      <Table className="dashbord-table" dataSource={viewModel?.companySubscriptionItems} columns={subscriptionColumns} loading={viewModel?.isProcessing}
                       bordered={true} pagination={false} 
                       
                       rowClassName={(record, index) => (record.alarm==true ?  "red":"blak") }
                       
                       />
                       <br/>
                       </Card>
                  
                  
               
               <br/>
         
     </div>

: "" }
  
   
    

    

    

     
     
                
                
                <br/>
                </div>
           
                :
                <Row gutter={[24, 16]}>
                    <Col offset={11} span={8}>
                        <Spin className={"spine"} size="large" />
                    </Col>
                </Row>
        }
        </div>
    )
}));

export default CustomerDashboard;
function dataIndex(value: any, index: number, array: any[]): unknown {
  throw new Error('Function not implemented.');
}

