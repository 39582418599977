import ChangeOilReportItem from "../handlers/get/ChangeOilReportItem";
import ChangeOilReportStore from "../stores/ChangeOilReportStore";
import {makeAutoObservable} from "mobx";
import GetChangeOilReportRequest from "../handlers/get/GetChangeOilReportRequest";
import GetChangeOilReportHandler from "../handlers/get/GetChangeOilReportHandler";
import GetChangeOilReportResponse from "../handlers/get/GetChangeOilReportResponse";
import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import {message} from "antd";
import UserContext from "../../../identity/contexts/UserContext";
import ObjectHelper from "../../../app/utils/ObjectHelper";

export default class GetChangeOilReportViewModel {
    columns: any[];
    changeOilReportList: ChangeOilReportItem[];
    changeOilReportExport: ChangeOilReportItem[];
    totalSize: number;
   
    isProcessing: boolean;
    errorMessage: string;
    getChangeOilReportRequest: GetChangeOilReportRequest = new GetChangeOilReportRequest();

    constructor(public changeOilReportStore: ChangeOilReportStore) {
        makeAutoObservable(this);

    }

    public async getAllChangeOilReport(getChangeOilReportRequest: GetChangeOilReportRequest, exportToFile: boolean = false) {
        try {
            this.errorMessage = "";
            if(ObjectHelper.isNullOrEmptyWithDefaultExceptions(getChangeOilReportRequest, [])){
                this.errorMessage = i18next.t("General.Search.AtLeastOne");
                return;
            }
            this.isProcessing = true;
            if(exportToFile)
                getChangeOilReportRequest.exportToFile = exportToFile;
            let response = await GetChangeOilReportHandler.get(getChangeOilReportRequest);

            if (response && response.success) {

                let result = response.data;
                let items = result.items;
                if(exportToFile)
                    this.changeOilReportExport = items;
                else {
                    this.changeOilReportList = items;
                    this.totalSize = result.totalCount;
    
                }
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('InvoiceSummaries.Error.Get.Message');
            log.error(e);
        } finally {
            getChangeOilReportRequest.exportToFile = false;
            this.isProcessing = false;
        }
    }

}
