import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import GetChangeOilReportViewModel from "../view-models/GetChangeOilReportViewModel";
import ListBranchViewModel from "../../../entities/branches/view-models/ListBranchViewModel";
import ListCarViewModel from "../../../entities/cars/view-models/ListCarViewModel";
import ListServiceMasterViewModel from "../../../entities/ServiceMasters/view-models/ListServiceMasterViewModel";
import ListCompanyViewModel from "../../../entities/companies/view-models/ListCompanyViewModel";

import ListCarUserViewModel from "entities/CarUser/view-models/ListCarUserViewModel";

export default class ChangeOilReportStore
{
    getChangeOilReportViewModel: GetChangeOilReportViewModel;

    listBranchViewModel: ListBranchViewModel;
    listCarViewModel: ListCarViewModel;
    listServiceMasterViewModel: ListServiceMasterViewModel;
    listCompanyViewModel: ListCompanyViewModel;
    listCarUserViewModel:ListCarUserViewModel;
    

    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onChangeOilReportGetPageLoad()
    {
        this.getChangeOilReportViewModel = new GetChangeOilReportViewModel(this);
        this.listBranchViewModel = new ListBranchViewModel();
        this.listCarViewModel = new ListCarViewModel();
        this.listServiceMasterViewModel = new ListServiceMasterViewModel();
        this.listCompanyViewModel = new ListCompanyViewModel();
        this.listCarUserViewModel=new ListCarUserViewModel();
    }

    onChangeOilReportGetPageUnload()
    {
        this.getChangeOilReportViewModel = null;
        this.listBranchViewModel = null;
        this.listCarViewModel = null;
        this.listServiceMasterViewModel = null;
        this.listCompanyViewModel = null;
        this.listCarUserViewModel=null;
    }

}
