import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import { Link } from "react-router-dom";
import "./ChangeOilReportList.scss";
import Stores from "app/constants/Stores";

import {
    Button, Collapse, Col, Row,
    Pagination, Input, Form,
    Table, PageHeader, Space, DatePicker, Select, Alert, Spin
} from "antd";
import {
    FolderViewOutlined,
    FileExcelOutlined,
    ArrowDownOutlined, ArrowUpOutlined,RiseOutlined ,FallOutlined ,SwapOutlined 
} from '@ant-design/icons';
import i18next from "i18next";
import GetChangeOilReportRequest from "../../handlers/get/GetChangeOilReportRequest";
import UserContext from "../../../../identity/contexts/UserContext";
import ChangeOilReportColumns from "./ChangeOilReportColumns";
import ChangeOilReportStore from "../../stores/ChangeOilReportStore";

import ExportExcel from "../../../../app/utils/ExportExcel";
import True from 'app/constants/True';



const { Panel } = Collapse;
const { Option } = Select;

interface ChangeOilReportListProps {
    changeOilReportStore?: ChangeOilReportStore
    
    
}

const ChangeOilReportList: React.FC<ChangeOilReportListProps> = inject(Stores.changeOilReportStore)(observer(({changeOilReportStore}) => {
    const [dataFetched, setDataFetched] = React.useState(false);
    const [carOptions, setCarOptions] = React.useState([]);
    const [carDriverOptions, setCarDriverOptions] = React.useState([]);
    const [branchOptions, setBranchOptions] = React.useState([]);
    const [companyOptions, setCompanyOptions] = React.useState([]);
    const [serviceMasterOptions, setServiceMasterOptions] = React.useState([]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };


    
    True.forEach(w =>{ w.title = i18next.t(w.title) });
    const TrueFalseopyion = [...True];
    
    const [form] = Form.useForm();
    ChangeOilReportColumns.forEach(w => {
       w.title = i18next.t(w.title);
    });



   // const companyColumn = {title: i18next.t("InvoiceSummaries.Label.companyName"),  dataIndex: "companyName", key: "companyName", responsive: ['md']};

  

    let columns: any[] = [...ChangeOilReportColumns,

    {
           
          
        title: i18next.t("ChangeOilReport.Label.kmleftUntilOilChange"),
        dataIndex: 'changeOilFlag', key: "changeOilFlag",
           render: (text, record) => (

            
            <div className="inline">
                
                
                
                {record.changeOilFlag >=-350 &&
                  <img style={{ width: 30,height:30}} src="/images/engine-oil.png" ></img>
                }
                
                { record.changeOilFlag  &&
                              
                              <h5  style={{ fontSize: '12px' ,color:'blue' }}>      {record.changeOilFlag }    </h5>
                           
                                         
                                   
        }
            </div>
            
           
        )
    }    
   

];
   



   /* if(UserContext.info.role == 100)
    {
        //columns.unshift(companyColumn);
      

        
    }
*/

    useEffect(() => {
        onLoad();

        return onUnload;
    }, []);

    async function onLoad() {
        changeOilReportStore.onChangeOilReportGetPageLoad();
        changeOilReportStore.getChangeOilReportViewModel.getChangeOilReportRequest = new GetChangeOilReportRequest();
        changeOilReportStore.getChangeOilReportViewModel.getChangeOilReportRequest.pageSize = 20;
        changeOilReportStore.getChangeOilReportViewModel.getChangeOilReportRequest.pageIndex = 0;
        changeOilReportStore.getChangeOilReportViewModel.getChangeOilReportRequest.companyId = UserContext.info.role === 1 ? UserContext.info.id : undefined;
        changeOilReportStore.getChangeOilReportViewModel.getChangeOilReportRequest.companyBranchId = UserContext.info.role === 5 ? UserContext.info.id : undefined;


        try {
          /*  if(UserContext.info.role === 100) {
                await changeOilReportStore.listCompanyViewModel.getCompanyList();
                let companyOptions = [];
                if (changeOilReportStore.listCompanyViewModel) {
                    for (let item of changeOilReportStore.listCompanyViewModel.listCompanyResponse.items) {
                        companyOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
                    }
                }
                setCompanyOptions(companyOptions);
           }  */  
          
            if(UserContext.info.role === 1) {
                await changeOilReportStore.listBranchViewModel.getBranchList();
                let branchOptions = [];
                if (changeOilReportStore.listBranchViewModel) {
                    for (let item of changeOilReportStore.listBranchViewModel.listBranchResponse.items) {
                        branchOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
                    }
                }
                setBranchOptions(branchOptions);
            }

            await changeOilReportStore.listCarViewModel.getCarList();
            let carOptions = [];
            if (changeOilReportStore.listCarViewModel) {
                if(UserContext.info.role !== 5) {
                    for (let item of changeOilReportStore.listCarViewModel.listCarResponse.items) {
                        carOptions.push(<Option key={item.key}   value={item.carNumber}>{item.carNumber}</Option>);
                    }
                }
                else{
                    const filteredCars = changeOilReportStore.listCarViewModel.listCarResponse.items.filter(w => w.branchId == UserContext.info.id);
                    for (let item of filteredCars) {
                        carOptions.push(<Option key={item.key} value={item.carNumber}  >{item.carNumber}</Option>);
                    }
                }
            }
            setCarOptions(carOptions);


             ///////////
            // ashraf
            // await invoiceSummaryStore.listCarUserViewModel.getCarUseList();
            // let carDriverOptions = [];
            // if (invoiceSummaryStore.listCarUserViewModel) {
            //     for (let item of invoiceSummaryStore.listCarUserViewModel.listCarUserResponse.items) {
            //      //   carDriverOptions.push(<Option key={item.key} value={item.key}>{item.carUserName}</Option>);
            //     }
            // }
            // setCarDriverOptions(carDriverOptions);


            await changeOilReportStore.listServiceMasterViewModel.getServiceMasterList();
            let serviceMasterOptions = [];
            if (changeOilReportStore.listServiceMasterViewModel) {
                for (let item of changeOilReportStore.listServiceMasterViewModel.listServiceMasterResponse.items) {
                    serviceMasterOptions.push(<Option key={item.key} value={item.title}>{item.title}</Option>);
                }
            }
            setServiceMasterOptions(serviceMasterOptions);

            setDataFetched(true);
           // await changeOilReportStore.getChangeOilReportViewModel.getAllChangeOilReport(changeOilReportStore.getChangeOilReportViewModel.getChangeOilReportRequest);
        }
        catch {

        }
    }

    let viewModel = changeOilReportStore.getChangeOilReportViewModel;

    if (!viewModel) return;

    function onUnload() {
        changeOilReportStore.onChangeOilReportGetPageUnload();
        setServiceMasterOptions([]);
        setCompanyOptions([]);
        setBranchOptions([]);
        setCarOptions([]);
        setCarDriverOptions([]);
    }

    async function pageIndexChanged(pageIndex, pageSize){
        viewModel.getChangeOilReportRequest.pageSize = pageSize;
        viewModel.getChangeOilReportRequest.pageIndex = pageIndex - 1;
        await changeOilReportStore.getChangeOilReportViewModel.getAllChangeOilReport(viewModel.getChangeOilReportRequest);
    }
    async function pageSizeChanged(current, pageSize){
        viewModel.getChangeOilReportRequest.pageSize = pageSize;
        viewModel.getChangeOilReportRequest.pageIndex = 0;
        await changeOilReportStore.getChangeOilReportViewModel.getAllChangeOilReport(viewModel.getChangeOilReportRequest);
    }
    function onChanged(e){
        viewModel.getChangeOilReportRequest[`${e.target.id}`] = e.target.value;
    }

    function onSelectChanged(e, propName){
        
        viewModel.getChangeOilReportRequest[`${propName}`] = e;
        if(propName === "companyId") {
            let carOptions = [];
            const filteredCars = changeOilReportStore.listCarViewModel.listCarResponse.items.filter(w => w.companyId == e);
            for (let item of filteredCars) {
             
                carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
            }
            setCarOptions(carOptions);

            let branchOptions = [];
            const filteredBranches = changeOilReportStore.listBranchViewModel.listBranchResponse.items.filter(w => w.companyId == e);
            for (let item of filteredBranches) {
                branchOptions.push(<Option key={item.key} value={item.key}>{item.title}</Option>);
            }
            setBranchOptions(branchOptions);
        }
        if(propName === "companyBranchId") {
            let carOptions = [];
            const filteredCars = changeOilReportStore.listCarViewModel.listCarResponse.items.filter(w => w.branchId == e);
            for (let item of filteredCars) {
                carOptions.push(<Option key={item.key} value={item.carNumber}>{item.carNumber}</Option>);
            }
            setCarOptions(carOptions);
        }


         if(propName === "carNeedChangeOil") {
        
           
            if(e=='True')
                e=-300;
           
             if(e=='False')
                e=-10000;
               
             viewModel.getChangeOilReportRequest.carNeedChangeOil = e;
            }



    }
    function onDateChange(date, dateString, prop) {
        viewModel.getChangeOilReportRequest[`${prop}`] = dateString;
    }
    async function onFinish(values: any) {
        viewModel.getChangeOilReportRequest.pageIndex = 0;
        await viewModel.getAllChangeOilReport(viewModel.getChangeOilReportRequest);
    }
    async function onReset(){
        const pageSize = viewModel.getChangeOilReportRequest.pageSize;
        viewModel.getChangeOilReportRequest = new GetChangeOilReportRequest();
        viewModel.getChangeOilReportRequest.pageIndex = 0;
        viewModel.getChangeOilReportRequest.pageSize = pageSize;
        if(UserContext.info.role == 1){
            changeOilReportStore.getChangeOilReportViewModel.getChangeOilReportRequest.companyId = UserContext.info.id;
        }
        await viewModel.getAllChangeOilReport(viewModel.getChangeOilReportRequest);
        form.resetFields();
    }
    async function ExportToExcel(){

     
        viewModel.changeOilReportExport = [];

        await viewModel.getAllChangeOilReport(viewModel.getChangeOilReportRequest, true);
        if(viewModel.changeOilReportExport && viewModel.changeOilReportExport?.length > 0) {
          //  columns.pop(); to remove last culom
            ExportExcel(columns, viewModel?.changeOilReportExport, "ChangeOilReport");
        }
    }

   
   

    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={i18next.t("ChangeOilReport.Page.Title")}
                subTitle={i18next.t("ChangeOilReport.Page.SubTitle")}
                extra={[
                    <Button className={"button-section"} key={"ExportExcel"} type="primary" loading={viewModel?.isProcessing} icon={<FileExcelOutlined />} onClick={ExportToExcel}>
                        {i18next.t("General.Button.ExportExcel")}
                    </Button>
                    ,
                ]}
            />

            <Collapse defaultActiveKey={['1']}className='searchPanelFotamt'>
                <Panel header={i18next.t("General.SearchPanel.Text")}  key="1">
                    <Form {...formItemLayout} layout={"vertical"} onFinish={onFinish} form={form}
                          key={"searchForm"}
                          scrollToFirstError>
                        <div>
                        {dataFetched ?
                        <Row gutter={[24, 16]}>
                            {UserContext.info.role === 100 ?
                                <Col span={6}>
                                    <Form.Item name="companyId" initialValue={viewModel?.getChangeOilReportRequest?.companyId}
                                               key={"companyId"}
                                               label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyName")}>
                                        {/*<Input onChange={onChanged}/>*/}
                                        <Select style={{width: "100%", display:"block"}} allowClear={true}
                                                showSearch={true} onChange={(e) => onSelectChanged(e, "companyId")}>
                                            {companyOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>: ""}
                            {/*<Col span={8}>
                                <Form.Item name="companyBranchName" initialValue={viewModel?.getInvoiceSummariesRequest?.companyBranchName}
                                           key={"companyBranchName"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyBranchName")}>
                                    <Input onChange={onChanged}/>
                                    <Select style={{width: "100%", display:"block"}}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "companyBranchName")}>
                                        {petropayAccountOptions}
                                    </Select>
                                </Form.Item>
                            </Col>*/}
                            {UserContext.info.role === 1 ?
                            <Col span={6}>
                                <Form.Item name="companyBranchId" initialValue={viewModel?.getChangeOilReportRequest?.companyBranchId}
                                           key={"companyBranchId"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.companyBranchName")}>
                                    {/*<Input onChange={onChanged} />*/}
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "companyBranchId")}>
                                        {branchOptions}
                                    </Select>
                                </Form.Item>
                            </Col> : "" }
                            
                            <Col span={6}>
                                <Form.Item name="carIdNumber" initialValue={viewModel?.getChangeOilReportRequest?.carIdNumber}
                                           key={"carIdNumber"}
                                           label={i18next.t("InvoiceSummaries.SearchPanel.Label.carIdNumber")}>
                                    {/*<Input onChange={onChanged}/>*/}
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "carIdNumber")}>
                                        {carOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

                             <Col span={4}>
                                <Form.Item name="carNeedChangeOil" initialValue={viewModel?.getChangeOilReportRequest?.carNeedChangeOil}
                                           key={"carNeedChangeOil"}
                                           label={i18next.t("ChangeOilReport.Label.carNeedChangeOil")}>
                                   
                                   <Select options={TrueFalseopyion}  allowClear={true} showSearch={true} onChange= {(e) => onSelectChanged(e, 'carNeedChangeOil')} />
                                  
                                </Form.Item>
                            </Col> 

                         

                         {/*   <Col span={6}>
                                <Form.Item name="carUserID" initialValue={viewModel?.getInvoiceSummariesRequest?.carDriverName}
                                           key={"carUserID"}
                                           label={i18next.t("InvoiceSummaries.Label.carDriverName")}>
                                    {/*<Input onChange={onChanged}/>
                                    <Select style={{width: "100%", display:"block"}} allowClear={true}
                                            showSearch={true} onChange={(e) => onSelectChanged(e, "carUserID")}>
                                        {carDriverOptions}
                                    </Select>
                                </Form.Item>
                            </Col>*/}

                           
                           
                            
                          
                                </Row>
                                    :
                                    <Row gutter={[24, 16]}>
                                        <Col offset={11} span={8}>
                                            <Spin className={"spine"} size="large" />
                                        </Col>
                                    </Row>}
                            </div>
                        <PageHeader
                            ghost={false}
                            subTitle={<div>
                                {viewModel?.errorMessage &&
                                <Alert message={viewModel?.errorMessage} type="error" />
                                }
                            </div>}
                            extra={[
                                <Button className={"button-section"} type="primary" loading={viewModel.isProcessing} onClick={onReset} danger key="reset" size={"large"} htmlType="reset">
                                    {i18next.t("General.SearchPanel.ResetButton")}
                                </Button>,
                                <Button className={"button-section"} type="primary" loading={viewModel.isProcessing} key="submit" size={"large"} htmlType="submit">
                                    {i18next.t("General.SearchPanel.SearchButton")}
                                </Button>
                            ]}
                        />
                    </Form>
                </Panel>
            </Collapse>
            <br  />
            <Table dataSource={viewModel?.changeOilReportList} columns={columns} loading={viewModel?.isProcessing}
                   bordered={true} pagination={false} sticky />
                
                                         
                   
            <br/>
            <Pagination
                total={viewModel?.totalSize}
                showSizeChanger
                showQuickJumper
                defaultPageSize={20}
                onChange={pageIndexChanged}
                onShowSizeChange={pageSizeChanged}
                showTotal={total => `Total ${total} items`}
            />
        </div>
    )
}));


export default ChangeOilReportList;


